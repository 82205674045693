export const log = e => console.log(e);

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function nativeNotification(
  title: string,
  body: string,
  tag: string,
  onclick: () => void = null
) {
  const options = {
    body: body,
    badge: "img/icons/favicon-32x32.png",
    icon: "img/icons/favicon-32x32.png",
    tag: tag
  };

  // Let's check if the browser supports notifications
  if (!("Notification" in window)) {
    alert("This browser does not support desktop notification");
  }

  // Let's check whether notification permissions have already been granted
  else if (Notification.permission === "granted") {
    // If it's okay let's create a notification
    const notification = new Notification(title, options);
    notification.onclick = () => {
      notification.close();
      if (onclick) onclick();
    };
  }

  // Otherwise, we need to ask the user for permission
  else if (Notification.permission !== "denied") {
    Notification.requestPermission().then(function(permission) {
      // If the user accepts, let's create a notification
      if (permission === "granted") {
        const notification = new Notification(title, options);
        notification.onclick = () => {
          notification.close();
          if (onclick) onclick();
        };
      }
    });
  }
}

export function intersect(o1, o2) {
  const [k1, k2] = [Object.keys(o1), Object.keys(o2)];
  const [first, next] = k1.length > k2.length ? [k2, o1] : [k1, o2];
  return first.filter(k => k in next);
}

export function renameKeys(obj, newKeys) {
  const keyValues = Object.keys(obj).map(key => {
    const newKey = newKeys[key] || key;
    return { [newKey]: obj[key] };
  });
  return Object.assign({}, ...keyValues);
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function dynamicSort(property) {
  let sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function(a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    const result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}

export function clickGoToUrl(url: string) {
  let link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  link.click();
}

export function downloadExport(response, filename: string) {
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(new Blob([response.data]));
  link.setAttribute("download", filename);
  link.click();
}
